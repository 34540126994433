import React from 'react';
import '../styles/person-card.css';

const PersonCard = ({ fullName, position, imageUrl, right }) => {
    return (
        <div className="person-card pt-2" data-aos={ right ? "fade-right" : "fade-left"}
             itemscope="" itemType="https://schema.org/Person">
            <div className="person-image">
                <img src={imageUrl} alt="Person" />
            </div>
            <div className={`person-info box-pink ${right ? 'person-info-right' : 'person-info-left'}`}
                 >
                <p className="fs-3 mt-1 pb-0" itemprop="name">{fullName}</p>
                <div itemProp="jobTitle">
                    <p itemProp="name">{position}</p>
                </div>
            </div>
        </div>
    );
};

export default React.memo(PersonCard);
