import React, {useEffect} from "react";
import AOS from "aos";
import Navbar from "../componenets/navbar";
import {Carousel } from "react-bootstrap";
import object_description from "../galleries/object_description";
import { useParams } from "react-router-dom";
import '../styles/object-look.css'
import {Helmet} from "react-helmet";

function ObjectLook() {
    const { key } = useParams();
    console.log(key);
    useEffect(() => {
        AOS.init();
    }, []);

    const object = object_description[key];

    return (
        <div>
            <Helmet
                title={object.name}
                meta={[
                    {"name": "description", "content": object.description }
                ]}
            />
            <Navbar></Navbar>
            <div className="first-element text-container ">
                <p className="fs-1 fw-light py-1 mt-4 gray-text">{object.name}</p>
                <div className="corner corner-top-right"></div>
                <Carousel>
                    {object.images.map((image, index) => (
                        <Carousel.Item key={index} interval={5000} style={{
                            height: "80vh",
                        }}>
                            <div className="d-flex justify-content-center align-items-center"
                                 style={{ height: "100%", width: "100%" }}>
                            <img src={image.src} alt="фото здания"
                                 style={{
                                     maxWidth: "100%",
                                     maxHeight: "100%",
                                     objectFit: "contain",
                                 }}
                            />
                            {image.title && (
                                <Carousel.Caption>
                                    <h3>{image.title}</h3>
                                    <p>{image.subtitle}</p>
                                </Carousel.Caption>
                            )}
                            </div>
                        </Carousel.Item>
                    ))}

                </Carousel>
            </div>



                <div className="box box-pink">
                    <div className="text-container">
                        <div className="facts d-flex justify-content-center py-2">
                            {object.facts.map((value, index) => (
                                <p className="value-item justify-content-center align-items-center d-flex"
                                   key={index}
                                   data-aos="fade-up"
                                style={{border: "1px solid white"}}>{value}</p>
                            ))}

                            <div className="corner corner-bottom-right"></div>
                            <div className="corner corner-top-left"></div>
                        </div>
                    </div>
                </div>

                <div className="text-container" style={{paddingBottom: 15 + "vh"}}>

                    <p className="fs-5 fst-italic fw-light mb-0 mt-4 gray-text" data-aos="fade-up">{"// Описание объекта"}</p>
                    <p data-aos="fade-up">{object.description}</p>
                </div>


        </div>

    )
}

export default ObjectLook;