import nmic1 from "../assets/objects/nmic-1.webp";
import nmic2 from "../assets/objects/nmic-2.webp";
import reconstruction1 from "../assets/objects/reconstruction1.webp";
import reconstruction2 from "../assets/objects/reconstruction2.webp";
import reconstruction3 from "../assets/objects/reconstruction3.webp";
import lapino1 from "../assets/objects/lapino1.webp";
import lapino2 from "../assets/objects/lapino2.webp";
import lapino3 from "../assets/objects/lapino3.webp";
import warehouse1 from "../assets/objects/warehouse1.webp";
import warehouse2 from "../assets/objects/warehouse2.webp";
import soc1 from "../assets/objects/soc1.webp";
import soc2 from "../assets/objects/soc2.webp";
import img2019 from "../assets/objects/2019.webp";
import img20191 from "../assets/objects/2019-1.webp";
import img20192 from "../assets/objects/2019-2.webp";
import heathCareSolution1 from "../assets/objects/heath-care-solution1.webp";
import heathCareSolution2 from "../assets/objects/heath-care-solution2.webp";
import heathCareSolution3 from "../assets/objects/heath-care-solution3.webp";
import childrensHospital1 from "../assets/objects/childrens-hospital.webp";
import childrensHospital2 from "../assets/objects/childrens-hospital2.webp";
import urbanPlanning1 from "../assets/objects/urban-planning1.webp";
import urbanPlanning2 from "../assets/objects/urban-planning2.webp";
import foundry1 from "../assets/objects/foundry1.webp";
import foundry2 from "../assets/objects/foundry2.webp";
import foundry3 from "../assets/objects/foundry3.webp";
import foundry4 from "../assets/objects/foundry4.webp";
import proffitable1 from "../assets/objects/proffitable1.webp";
import proffitable2 from "../assets/objects/proffitable2.webp";


const object_description = {
    'nmic': {
        name: "Административно-лабораторный корпус НМИЦ Кулакова",
        facts: ['Москва, ЮЗАО', 'Площадь объекта: 5 166 м²', '2023'],
        images: [
            {
                src: nmic1,
                title: "",
                subtitle: ""
            },
            {
                src: nmic2,
                title: "",
                subtitle: ""
            }
        ],
        description: 'Проектируемое здание предназначено для размещения на его территории лабораторно-производственных блоков генной инженерии.',
    },
    'reconstruction-of-the-microbiological-laboratory': {
        name: "Реконструкция микробиологической лаборатории и устройство физико-химической и R&D лабораторий",
        facts: ['Санкт-Петербург', 'Площадь объекта: 1 200 м²', '2022'],
        images: [
            {
                src: reconstruction1,
                title: "Существующее положение",
                subtitle: ""
            },
            {
                src: reconstruction2,
                title: "",
                subtitle: ""
            },
            {
                src: reconstruction3,
                title: "",
                subtitle: ""
            }
        ],
        description: 'Микробиологическая лаборатория предназначена для микробиологических испытаний исходного сырья и материалов, промежуточной и готовой продукции. \n' +
            'Задачей физико-химической лаборатории является проведение исследований на качественный и количественный анализ лекарственных средств и их компонентов по специализированным методикам. \n',
    },
    'radiological-center-lapino-3': {
        name: "Радиологический центр Лапино-3",
        facts: ['Московская область', 'Площадь объекта: 11 000 м²', '2020/21'],
        images: [
            {
                src: lapino1,
                title: "",
                subtitle: ""
            },
            {
                src: lapino2,
                title: "",
                subtitle: ""
            },
            {
                src: lapino3,
                title: "",
                subtitle: ""
            }
        ],
        description: 'Здание проектируемого  радиологического корпуса\n' +
            'представляет собой сложный  многофункциональный объем. \n' +
            'Лапино 3 запроектировано с  переменной этажностью: 2-3  этажа.\n' +
            'Здание состоит из нескольких  разных функциональных блоков,  взаимосвязанных и\n' +
            'взаимозависимых друг  относительно друга.\n',
    },
    'warehouse-complex-for-blood-plasma-storage': {
        name: "Складской комплекс хранения плазмы крови",
        facts: ['г. Скопин, Рязанская область', 'Площадь объекта: 3 000м²', '2023'],
        images: [
            {
                src: warehouse1,
                title: "",
                subtitle: ""
            },
            {
                src: warehouse2,
                title: "",
                subtitle: ""
            },
        ],
        description: 'Cкладской комплекс для хранения плазмы крови человека, предназначенной для фракционирования, \n' +
            'собранной в российской сети плазмоцентров, а также поставленной от зарубежных партнеров\n' +
            'для использования на производстве. Административно-лабораторный корпус предназначен для размещения административного персонала и помещений центральной лаборатории контроля плазмы человека для\n' +
            'фракционирования.',
    },
    'social-charitable-center-for-work-with-the-elderly': {
        name: "Складской комплекс хранения плазмы крови",
        facts: ['Москва', 'Площадь объекта: 3 000м²', '2022'],
        images: [
            {
                src: soc1,
                title: "",
                subtitle: ""
            },
            {
                src: soc2,
                title: "",
                subtitle: ""
            },
        ],
        description: 'Данный проект реализует возможность увеличения доступности социальных благ и досуга для людей пожилого возраста. Здание с внутренним ядром-атриумом сочетает в себе различный функционал направленный на помощь и адаптацию людей преклонного возраста.\n',
    },
    'center-for-hybrid-receptor-metabolic-imaging-and-personalized-theranostics': {
        name: "Центр гибридной  рецепторно-метаболической  визуализации и\n" +
            "персонализированной  тераностики\n",
        facts: ['Москва', 'Площадь объекта: 7 200м²', '2019'],
        images: [
            {
                src: img2019,
                title: "",
                subtitle: ""
            },
        ],
        description: 'Концепция здания выполнена в  современном академическом  стиле. \n' +
            'Сдержанная и лаконичная  графика фасадов с использованием градиентной кирпичной облицовки. \n' +
            'Большая  площадь остекления палат, аудиторий, общественных мест и  лабораторных зон.\n' +
            'Начиная с уровня второго этажа  и до крыши сделано внутреннее открытое пространство.\n' +
            'Расположенный в самом центре  здания, этот воздушный объем  характеризует экологичность  проекта и создает взаимопроникающую динамику  света, внешней среды (улицы) и  наполненных светом помещений.',
    },
    'first-city-clinical-hospital-named-after-n-i-pirogova': {
        name: "Первая городская клиническая больница им. Н. И. Пирогова",
        facts: ['Севастополь', 'Площадь объекта: 4 200м²', '2019'],
        images: [
            {
                src: img20191,
                title: "",
                subtitle: ""
            },
            {
                src: img20192,
                title: "",
                subtitle: ""
            },
        ],
        description: 'Эскизный проект приёмного  отделения скорой медицинской  помощи в отдельно стоящем  проектируемом здании с переходами между хирургическим корпусом и  сосудистым центром.\n' +
            'Мощность объекта:\n' +
            'Палатный блок 12 коек;\n' +
            'Реанимация на 6 пациентов;\n' +
            'Диагностические и процедурные  кабинеты;\n' +
            'Операционные;\n' +
            '2 конференц-зала.\n',
    },
    'health-care-solution': {
        name: "OOO «Health care solutions» Центр ядерной медицины",
        facts: ['Ташкент, Республика Узбекист', 'Площадь объекта: 1 300м²', '2018/19'],
        images: [
            {
                src: heathCareSolution1,
                title: "",
                subtitle: ""
            },
            {
                src: heathCareSolution2,
                title: "",
                subtitle: ""
            },
            {
                src: heathCareSolution3,
                title: "",
                subtitle: ""
            },
        ],
        description: 'Подготовка проектной документации строительства центра  позитронно – эмиссионной томографии.\n' +
            'В составе многопрофильного  медицинского центра выполнены этапы: \n' +
            '- Разработка медико – технического задания;\n' +
            '- Разработка  дорожной карты строительства;\n' +
            '- Разработка концепции ПЭТ-центра, включающего производство радиофармпрепаратов.\n' +
            'Операционная мощность:\n' +
            '- ПЭТ/КТ - 12 тыс. процедур/год;\n' +
            '- ОФЭКТ - 15 тыс. исследований/год\n',
    },
    'childrens-hospital': {
        name: "Детская поликлиника со стационаром и лабораторией на 150 посещений в смену",
        facts: ['Тарко-Сале, ЯНАО', 'Площадь объекта: 16 500м²', '2021/22'],
        images: [
            {
                src: childrensHospital1,
                title: "",
                subtitle: ""
            },
            {
                src: childrensHospital2,
                title: "",
                subtitle: ""
            },
        ],
        description: 'Детская поликлиника на 150 посещений в смену. \n' +
            'Оснащена стационаром на 30 коек. Отдельным крылом идет многофункциональная лаборатория для проведения всего спектра анализов.\n' +
            'Здание поделено на блоки различного функционального значения, в составе здания отделение ЛФК, малая операционная, кабинеты профильных детских врачей. \n',
    },
    'URBAN-PLANNING': {
        name: "ГРАДОСТРОИТЕЛЬНАЯ КОНЦЕПЦИЯ РАЗМЕЩЕНИЯ ОБЪЕКТОВ СПОРТА",
        facts: ['г.о. Серпухов, Московская область', 'Площадь объекта: 76 500м²', '2018'],
        images: [
            {
                src: urbanPlanning1,
                title: "",
                subtitle: ""
            },
            {
                src: urbanPlanning2,
                title: "",
                subtitle: ""
            },
        ],
        description: 'В границах рассматриваемой территории площадью 15,7085Га, схемой архитектурно-планировочной организации территории предусматривается размещение 3-х крытых спортивных сооружений — ледовый дворец (3-этажа) на 10000 зрителей с большой и малой (тренировочной) ледовой ареной, волейбольный дворец (3-этажа) на 5500 зрителей с центральной ареной и тренировочной ареной, плавательный бассейн (2 этажа) на 623 зрительских места с встроенным фитнес-центром, здание\n' +
            'гостиничного комплекса (9 этажей+ эксплуатируемая кровля) на 256 номеров с встроенным спортивно-медицинским центром (2 этажа).\n',
    },
    'foundry': {
        name: "Литейное производство с АБК для нужд предприятия «General motors Powertrain»",
        facts: ['г. Ташкент, Узбекистан', 'Площадь объекта: 15 500м²', '2011'],
        images: [
            {
                src: foundry1,
                title: "",
                subtitle: ""
            },
            {
                src: foundry2,
                title: "",
                subtitle: ""
            },
            {
                src: foundry3,
                title: "",
                subtitle: ""
            },
            {
                src: foundry4,
                title: "",
                subtitle: ""
            },
        ],
        description: 'В рамках проектирования и строительства Предприятия по производству силовых агрегатов Дженерал Моторс Пауэртрейн-Узбекистан, был спроектирован и возведен литейный цех по отливке корпусов двигателей внутреннего сгорания из легкоплавких металлов.\n',
    },
    'profitable-house-of-the-stulov-brothers': {
        name: "Доходный дом братьев Стуловых",
        facts: ['Москва', 'Площадь объекта: 11 000м²', '2022/23'],
        images: [
            {
                src: proffitable1,
                title: "",
                subtitle: ""
            },
            {
                src: proffitable2,
                title: "",
                subtitle: ""
            },
        ],
        description: 'Реставрация ОКН в рамках создания музейного квартала  ГМИИ им Пушкина.Проектом предусматривается создание библиотеки, читальных и выставочных залов, зон рекреации и кафе.\n',
    },
}

export default object_description;