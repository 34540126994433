import React, {Component} from 'react';
import '../styles/portfolio.css';

class ImageGallery extends Component {
    render() {
        const {images} = this.props;

        return (
            <div className="portfolio">
                <span style={{display: "none"}}>Объекты, которые мы делаем</span>
                {images.map((image, index) => (

                        <div className="image-container" key={index}

                             data-aos={index % 2 === 0 ? 'zoom-in-right' : 'zoom-in-left'}>
                            <img src={image.src} alt={image.alt} loading="lazy"
                                 style={{
                                     backgroundColor: "gray",
                                 }}
                                 itemProp="image"
                            ></img>
                            {/*<span*/}
                            {/*    style={{display: "none"}}*/}
                            {/*    itemProp="name"*/}
                            {/*>*/}
                            {/*    {images.alt}*/}
                            {/*</span>*/}
                            <a href={"/object/" + image.more}>
                            <div className="overlay">
                                <button className="learn-more">
                                <span className="circle" aria-hidden="true">
                                    <span className="icon arrow"></span>
                                </span>
                                    <span className="button-text">Подробнее</span>
                                </button>
                            </div>
                        </a>

                    </div>
                ))}
            </div>
        );
    }
}

export default React.memo(ImageGallery);
