import {Helmet} from "react-helmet";
import React from "react";

export default function NotFound() {
    return (
        <div>
            <Helmet
                title="О Нас"
                meta = {[
                    {"name": "errorpage", "content": "true"},
                    {"name": "errortype", "content": "404 - Not Found"},
                    {"name": "prerender-status-code", "content": "404"}
                ]}
            />
            <h1>Кажется вы потерялись...</h1>
        </div>
    )
}