import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainPage from "../pages/MainPage";
import AboutUs from "../pages/AboutUs";
import Portfolio from "../pages/Portfolio";
import Appoitment from "../pages/DatePicker";
import ObjectLook from "../pages/ObjectLook";
import PrivacyPage from "../pages/PrivacyPolicy";
import {Helmet} from "react-helmet";
import NotFound from "../pages/notFound";
import AdditionalServices from "../pages/AdditionalServices";


function AppRouter() {

    return (
        <div>
            <Helmet
                titleTemplate="%s - МВМ"
                defaultTitle="ООО МВМ"
                meta={[
                    // {"name": "description", "content": "МВМ - команда профессионалов в области инжиниринга и дизайна. Мы создаем проекты, отвечающие международным стандартам высокотехнологичной среды. Планируем и реализуем технологические проекты с акцентом на инновации, партнерство и эффективность. Эксперты в проектировании с членством в СРО. Познакомьтесь с нами ближе!"},
                    // {"property": "og:type", "content": "article"}
                ]}
                />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/objects" element={<Portfolio />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/additional-services" element={<AdditionalServices />} />
                    <Route path="/appointment" element={<Appoitment />} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/policy" element={<PrivacyPage />} />

                    <Route path="/object/:key" element={<ObjectLook />} />
                    <Route path='*' element={<NotFound />} status={404}/>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default AppRouter;