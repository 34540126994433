import Navbar from "../componenets/navbar";
import {Col, Container, Row} from "react-bootstrap";
import '../styles/about-us.css';
import React, {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Helmet} from "react-helmet";
import {ReactComponent as DesignServices} from '../assets/design-services.svg';
import citationCircleWeb from "../assets/mainPage/image-circle.webp";


const services = [
    {
        "service_name": "Создание проектов для промышленного и гражданского строительства"
    },
    {
        "service_name": "Сокращение сметной стоимости за счет изменения инженерных и технических решений"
    },
    {
        "service_name": "Выполнение инженерно-геологических, геодезических, экологических изысканий"
    },
    {
        "service_name": "Обмерочные (обмерные) работы"
    },
    {
        "service_name": "Анализ проекта специалистами по инженерно-строительным направлениям"
    },
    {
        "service_name": "Анализ объекта с выездом специалиста и предложением оптимизации"
    },
    {
        "service_name": "Обследование состояния строительных конструкций, инженерных систем зданий и сооружений"
    },
    {
        "service_name": "Нормативный контроль"
    },
    {
        "service_name": "Расчет стоимости проектирования по сборнику базовых цен"
    },
    {
        "service_name": "Сопровождение экспертизы проектно-сметной документации"
    },
    {
        "service_name": "Кадастровые работы"
    },
    {
        "service_name": "Выполнение научно-исследовательских работ по реставрации"
    },
    {
        "service_name": "Юридическое сопровождение полного цикла строительства"
    },
    {
        "service_name": "Оценка градостроительного потенциала земельного участка и сопровождение"
    },
    {
        "service_name": "Анализ градостроительной документации"
    },
    {
        "service_name": "Сопровождение оформления земельно-правовых отношений"
    },
    {
        "service_name": "Анализ возможности подключения к сетям ресурсоснабжающих организаций"
    },
    {
        "service_name": "Получение разрешение на строительство"
    },
    {
        "service_name": "Получение ордера на производство земляных работ"
    },
    {
        "service_name": "Получение порубочного билета"
    },
    {
        "service_name": "Авторский и строительный надзор"
    }
]

const firstFiveServices = services.slice(0, 5);
const remainingServices = services.slice(5);


function AdditionalServices() {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div>
            <Helmet
                title="Дополнительные услуги"
            />

            <Container fluid={true}>
                <Navbar></Navbar>
                <div className="first-element text-container pb-md-5">
                    <p className="fs-1 mb-5">{"// Дополнительные услуги"}</p>
                    <div className="corner corner-bottom-left"></div>
                    <div className="corner corner-bottom-right"></div>
                    <hr></hr>


                    <div className="fs-2 mt-4">
                        {firstFiveServices.map((service, index) => (
                            <div className="service-item" key={index} data-aos="fade-up">
                                <div className="service-icon">
                                    <DesignServices></DesignServices>
                                </div>
                                <p className="mt-0" itemProp="name">{service.service_name}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="box box-pink">
                    <div className="text-container">
                        <div className="container-fluid">
                            <Row>
                                <Col xs={12} md={9}>
                                    <p className="fs-5 fst-italic fw-light mb-1 pb-0">{"// Сложно разобраться?"}</p>
                                    <p className="mt-0 fs-3 lh-sm">Мы можем помочь вам с выбором на бесплатной консультации</p>
                                </Col>
                                <Col xs={12} md={3}
                                     className="d-flex align-items-center">

                                    <a href="#email-form">
                                        <button className="learn-more gray-text" style={{width: 18 + "rem"}}
                                                data-aos="flip-down">
                                            <span className="circle" aria-hidden="true">
                                              <span className="icon arrow"></span>
                                            </span>
                                            <span className="button-text" style={{ color: "white"}}> Записаться на нее</span>
                                        </button>
                                    </a>
                                </Col>
                            </Row>
                        </div>


                    </div>
                </div>
                {/* Вставьте свой код здесь */}
                <div className="text-container pb-md-5 mt-3">
                    <div className="corner corner-bottom-left"></div>
                    <div className="corner corner-bottom-right"></div>
                    <div className="fs-2 pb-4">

                    {remainingServices.map((service, index) => (
                            <div className="service-item" key={index + firstFiveServices.length} data-aos="fade-up">
                                <div className="service-icon">
                                    <DesignServices></DesignServices>
                                </div>
                                <p className="mt-0" itemProp="name">{service.service_name}</p>
                            </div>
                        ))}
                    </div>
                </div>

            </Container>
        </div>
    )
}

export default AdditionalServices;