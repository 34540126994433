import nmic1 from "../assets/objects/nmic-1.webp";
import reconstruction2 from "../assets/objects/reconstruction2.webp";
import lapino1 from "../assets/objects/lapino1.webp";
import warehouse1 from "../assets/objects/warehouse1.webp";
import soc1 from "../assets/objects/soc1.webp";
import img2019 from "../assets/objects/2019.webp";
import img20191 from "../assets/objects/2019-1.webp";
import heathCareSolution1 from "../assets/objects/heath-care-solution1.webp";
import childrensHospital1 from "../assets/objects/childrens-hospital.webp";
import urbanPlanning1 from "../assets/objects/urban-planning1.webp";
import foundry1 from "../assets/objects/foundry1.webp";
import proffitable1 from "../assets/objects/proffitable1.webp";

const allObjects = [
    {
        src: nmic1,
        more: 'nmic',
        alt: '#',
        title: '#'
    },
    {
        src: reconstruction2,
        more: 'reconstruction-of-the-microbiological-laboratory',
        alt: '#',
        title: '#'
    },
    {
        src: lapino1,
        more: 'radiological-center-lapino-3',
        alt: '#',
        title: '#'
    },
    {
        src: warehouse1,
        more: 'warehouse-complex-for-blood-plasma-storage',
        alt: '#',
        title: '#'
    },
    {
        src: soc1,
        more: 'social-charitable-center-for-work-with-the-elderly',
        alt: '#',
        title: '#'
    },
    {
        src: img2019,
        more: 'center-for-hybrid-receptor-metabolic-imaging-and-personalized-theranostics',
        alt: '#',
        title: '#'
    },
    {
        src: img20191,
        more: 'first-city-clinical-hospital-named-after-n-i-pirogova',
        alt: '#',
        title: '#'
    },
    {
        src: heathCareSolution1,
        more: 'health-care-solution',
        alt: '#',
        title: '#'
    },
    {
        src: childrensHospital1,
        more: 'childrens-hospital',
        alt: '#',
        title: '#'
    },
    {
        src: urbanPlanning1,
        more: 'URBAN-PLANNING',
        alt: '#',
        title: '#'
    },
    {
        src: foundry1,
        more: 'foundry',
        alt: '#',
        title: '#'
    },
    {
        src: proffitable1,
        more: 'profitable-house-of-the-stulov-brothers',
        alt: '#',
        title: '#'
    }
];

export default allObjects;
