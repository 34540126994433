import React from 'react';
import '../styles/RunningImagesComponent.css';
import revit from "../assets/logos/revit.png";
import autocad from "../assets/logos/autocad.png";
import archicad from "../assets/logos/archicad.png";
import scad from "../assets/logos/scad.png";
import lumion from "../assets/logos/lumion.png";
import liraland from "../assets/logos/liraland.png";
import Marquee from "react-fast-marquee";

const logos = [
    revit,
    archicad,
    autocad,
    scad,
    lumion,
    liraland,
];

const CardContainer = () => {
    return (
        <div className="card-container">
            <Marquee autoFill={true}>
                {logos.map((logoSrc, index) => (
                    <div className="program-card" key={index}>
                        <img src={logoSrc} alt="Program Logo" />
                    </div>
                ))}
            </Marquee>
        </div>
    );
};


export default React.memo(CardContainer);
