import Navbar from "../componenets/navbar";
import {Container} from "react-bootstrap";
import '../styles/about-us.css';
import React, {useEffect} from "react";
import AOS from "aos";
import ImageGallery from "../componenets/image-gallery";

import allObjects from "../galleries/allObjects";
import {Helmet} from "react-helmet";



function Portfolio() {

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div>
            <Helmet
                title="Наши проекты"
                meta = {[
                    {"name": "description", "content": "Посмотрите нашу галерею проектов и объектов, реализованных компанией ООО МВМ. Мы представляем широкий спектр работ в области проектирования и инжиниринга, которые подчеркивают наш опыт и качество."}
                ]}
            />

            <Container fluid={true}>
                <Navbar></Navbar>
                <div className="first-element text-container pb-md-5">
                    <p className="fs-1 mb-5">{"// Наши проекты"}</p>
                    <div className="corner corner-bottom-left"></div>
                    <div className="corner corner-bottom-right"></div>
                    <hr></hr>
                    <ImageGallery images={allObjects}></ImageGallery>
                </div>

            </Container>
        </div>
    )
}

export default Portfolio;