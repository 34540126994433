
import React from 'react';

function Navbar (){
    return (
        <header className="navbar-custom">

            <a href="/"><h1 className="mvm-text">МВМ</h1></a>
            <ul className="links">

                <li><a href="/objects" className="link big-link">Наши Проекты</a></li>
                <li><a href="/about" className="link big-link">О Нас</a></li>
                <li>
                    <a href="#email-form">
                        <div className="contact-us">
                            <span>Связаться с нами</span>
                        </div>
                    </a>
                    {/*<a href="#" className="button-gray"> Связаться с нами</a>*/}
                </li>

            </ul>

        </header>
    )
}

export default React.memo(Navbar);