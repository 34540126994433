import React, {lazy} from 'react';
import { hydrate, render } from "react-dom";
import './styles/index.css';
import './styles/button.css';

import AppRouter from "./componenets/app-router";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Suspense } from 'react'


const BottomPage = lazy(() => import("./componenets/bottom-page"))

const rootElement = document.getElementById("root");

const Application = (
    <React.StrictMode>

        <AppRouter />
        <Suspense fallback={<div>Loading...</div>}>
            <BottomPage   />
        </Suspense>

    </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
    hydrate(Application, rootElement);
} else {
    render(Application, rootElement);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
