import Navbar from "../componenets/navbar";
import {Col, Row} from "react-bootstrap";
import mainImg from "../assets/about-us-main.webp";
import '../styles/about-us.css';
import BuildingProcess from "../componenets/stages";
import CardContainer from "../componenets/slider";
import PersonCard from "../componenets/person-card";
import misha from '../assets/misha.png';
import katya from '../assets/katya.png';
import React, {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Helmet} from "react-helmet";

const values = ["Креативность", "Инновации", "Партнерство", "Эффективность", "Развитие"]



function AboutUs() {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div>
            <Helmet
                title="О Нас"
                meta = {[
                    {"name": "description", "content": "ООО МВМ - профессионалы в области проектирования и инжиниринга. Узнайте больше о нашей команде опытных специалистов, нашем подходе к работе и наших ценностях"}
                ]}
            />
            <Navbar></Navbar>
            <div className="first-element text-container  container-fluid">
                <Row className="justify-content-between main-mobile">
                    <Col md={5} xs={11} className="pb-md-3">
                        <p className="title mt-0 mb-0">МЫ - МВМ <br/> Команда профессионалов в области инжиниринга и дизайна</p>
                        <p className="subtitle mt-2" style={{textAlign: "left"}}>Мы создаем проекты, отвечающие международным стандартам высокотехнологичной среды. Возведение технологического проекта – это инвестиция, которая не должна превращаться в долгострой. Такому проекту нужно точное планирование: от идеи до результата.</p>
                        <p className="fs-5 fst-italic fw-light mb-1 pb-0 gray-text">{"// Мы Ценим"}</p>
                        <div className="values fs-6 ">
                            {values.map((value, index) => (
                                <p className="value-item" key={index} data-aos="fade-up">{value}</p>
                            ))}
                        </div>
                    </Col>
                    <Col md={5} xs={11} className="image-column" style={{
                        backgroundImage: `url(${mainImg})`
                    }}>

                    </Col>
                </Row>
                <div className="corner corner-bottom-left"></div>

            </div>

                <div className="box box-pink">
                    <div className="text-container" itemScope="" itemType="http://schema.org/ProgramMembership" itemprop="ProgramMembership">
                    <p className="fs-5 fst-italic fw-light mb-1 pb-0" itemprop="programName">{"// Мы состоим в СРО"}</p>
                        <p className="mt-0 fs-3 lh-sm">Доверьте свой проект профессионалам. МВМ - эксперты в проектировании с ответственностью до 25 млн. рублей и членством в саморегулируемой организации "ЭкспертПроект" (<span itemProp="membershipNumber">регистрационный номер: П-182-0097090806122-3020).</span> </p>
                </div>
            </div>
            <div className="text-container">
                <p className="fs-5 fst-italic fw-light mb-1 mt-3 pb-0 gray-text">{"// Как мы работаем"}</p>
                <div className="corner corner-top-right"></div>
                <div className="corner corner-bottom-left"></div>
                <BuildingProcess/>
            </div>
            <div className="box box-pink">
                <div className="text-container">
                    <p className="fs-5 fst-italic fw-light mb-1 pb-3">{"// Мы используем современное ПО"}</p>
                </div>
                <CardContainer></CardContainer>
            </div>
            <div className="text-container">
                <p className="fs-5 fst-italic fw-light mb-1 pt-3 pb-0 gray-text">{"// Познакомьтесь с нами поближе"}</p>
                <div className="corner corner-top-right"></div>
                <div className="corner corner-top-left"></div>
            </div>

            <div className="container-fluid pb-5">
            <Row className="mb-5">
                <Col xs={12} md={6}>
                    <PersonCard fullName="Никитин Михаил" position="Управляющий" imageUrl={misha} right={true}></PersonCard>

                </Col>
                <Col xs={12} md={6}>
                    <PersonCard fullName="Тягунова Екатерина" position="Операционный управляющий" imageUrl={katya} right={false}></PersonCard>
                </Col>
            </Row>
            </div>
        </div>

    )
}

export default AboutUs;