import React, { useState, useEffect } from "react";
import SlotPicker from "slotpicker";
import '../styles/appoitment.css'
import {useLocation} from "react-router-dom";
import AOS from "aos";
import 'aos/dist/aos.css';

function Appoitment() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);


    useEffect(() => {
        AOS.init(); // Инициализируйте AOS
    }, []);


    const name = searchParams.get("name");
    const uuid = searchParams.get("uuid");

    const [selectedDate, setSelectedDate] = useState(null);
    const [unavailableSlots, setUnavailableSlots] = useState(['12:00', '13:00', '14:00', '15:00', '16:00', '17:00']);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [clientComment, setClientComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isEnded, setIsEnded] = useState(false);

    const isAvailableDay = (date) => {
        const now = new Date();

        // Проверяем, что дата находится в будущем
        if (date <= now) {
            return false;
        }

        // Проверяем, что день недели - Понедельник, Среда или Четверг
        const dayOfWeek = date.getDay();
        return [1, 3, 4].includes(dayOfWeek);


    };

    useEffect(() => {
        if (selectedDate) {
            if (isAvailableDay(selectedDate)) {
                fetchUnavailableSlots(selectedDate);
            } else {
                setUnavailableSlots(['12:00', '13:00', '14:00', '15:00', '16:00', '17:00']);
            }
        }
    }, [selectedDate]);

    const fetchUnavailableSlots = async (date) => {
        try {
            setIsLoading(true); // Устанавливаем состояние загрузки в true

            const url = '/d4e9mdegjjoo62rks3ht';
            const requestData = {
                date: date.toISOString().split('T')[0]
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            const responseData = await response.json();
            if (response.ok) {
                setUnavailableSlots(responseData);
            } else {
                console.error('Error:', responseData.message);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false); // Завершаем состояние загрузки
        }
    };

    const handleDateChange = (event) => {
        const selectedDateValue = new Date(event.target.value);
        setSelectedDate(selectedDateValue);
        setSelectedSlot(null);
    };

    const handleSlotSelect = (slot) => {
        setSelectedSlot(slot);
    };

    const handleClientCommentChange = (event) => {
        setClientComment(event.target.value);
    };

    function formatDateToCustomString(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    const handleConfirmBooking = () => {
        if (selectedDate && selectedSlot) {

            let date_days = new Date(selectedDate);
            let time = new Date(selectedSlot.$d);
            let final_data = new Date(date_days.toISOString().split('T')[0] + 'T' + time.toISOString().split('T')[1])

            const bookingData = {
                uuid: uuid,
                datetime: formatDateToCustomString(final_data),
                comment2: clientComment
            };


            sendBookingData(bookingData);

        } else {
            console.log("Please fill in all required information");
        }
    };


    const sendBookingData = async (bookingData) => {
        try {
            setIsSending(true)
            const url = '/d4e4tgvhnlevrk5s0klu';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bookingData)
            });

            // const responseData = await response.json();
            if (response.ok) {
                setIsEnded(true);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsSending(false);
        }
    };




    if (!name || !uuid) {
        return null; // Don't render anything if either variable is undefined
    }


    return (
        <div className="container mb-2 pb-5">
            <div className="corner corner-top-right"></div>
            <p className="fs-1">{"// Запись на консультацию"}</p>
            <p>{name}, здравствуйте! <br/> Выберите дату и время</p>
            <input
                type="date"
                value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
                onChange={handleDateChange}
            />
            {isLoading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow" role="status">
                        {/*<span className="sr-only">Loading...</span>*/}
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <SlotPicker
                        interval={60}
                        onSelectTime={handleSlotSelect}
                        unAvailableSlots={unavailableSlots}
                        from={'12:00'}
                        to={'17:00'}
                        selectedSlotColor='#F09999'
                        lang='en'
                    />
                </React.Fragment>

            )}


            <p data-aos="fade-up">Если вы хотите, чтобы мы тщательнее подготовились к нашей встрече вы можете оставить комментарий о том, что вы хотите сделать </p>
            <textarea
                placeholder="Комментарий клиента"
                value={clientComment}
                onChange={handleClientCommentChange}
                data-aos="fade-up"
            />
            <div className="d-flex justify-content-center">
                {isSending ? (
                    <div className="spinner-grow text-dark" role="status">
                        {/*<span className="sr-only">Loading...</span>*/}
                    </div>
                    ) : isEnded ? (
                    <p className="button-form text-center" data-aos="fade-up">Отправили вам письмо</p>
                    ) : (
                        <button className="learn-more gray-text" style={{width: 20 + "rem"}}

                                onClick={handleConfirmBooking}
                                data-aos="fade-up">
                            <span className="circle" aria-hidden="true">
                              <span className="icon arrow"></span>
                            </span>

                            <span className="button-text">Подтвердить запись</span>
                        </button>
                    )}

            </div>
        </div>
    )
}

export default Appoitment;
