import foundry1 from "../assets/objects/foundry1.webp";
import proffitable1 from "../assets/objects/proffitable1.webp";
import childrensHospital1 from "../assets/objects/childrens-hospital.webp";
import img20191 from "../assets/objects/2019-1.webp";


let mainPageGallery = [

    {
        src: childrensHospital1,
        more: "childrens-hospital",
        alt: "#",
        title: "#",
    },
    {
        src: foundry1,
        more: "foundry",
        alt: "#",
        title: "#",
    },
    {
        src: proffitable1,
        more: "profitable-house-of-the-stulov-brothers",
        alt: "",
        title: "#",
    },

    {
        src: img20191,
        more: "first-city-clinical-hospital-named-after-n-i-pirogova",
        alt: "#",
        title: "#",
    }
]

export default mainPageGallery;