import React, { useState } from 'react';
import '../styles/stages.css'; // Импортируйте стили
import image1 from '../assets/stages/stage1.png';
import image2 from '../assets/stages/stage2.png';
import image3 from '../assets/stages/stage3.png';
import image4 from '../assets/stages/stage4.png';
import image5 from '../assets/stages/stage5.png';
import image6 from '../assets/stages/stage6.png';


const BuildingProcess = () => {
    const stagesData = [
        { id: 1, title: 'Концепция',
            description: 'Поможем Вам подобрать пути для реализации вашей идеи и создать грамотное техническое задание для ее воплощения',
            image: image1 },
        { id: 2, title: 'Предпроект',
            description: 'Покажем Вам как будет выглядеть воплощение вашей идеи в объемном и графическом виде, а также подберем технологические решения для ее реализации',
            image: image2 },
        { id: 3, title: 'Проектирование',
            description: 'Сопроводим в прохождении экспертизы и поможем в получении разрешения на строительство',
            image: image3 },
        { id: 4, title: 'Надзор за Строительством',
            description: 'Разрабатываем рабочую документацию и осуществляем контроль качества всех этапов строительства',
            image: image4 },
        { id: 5, title: 'Эксплутационная модель',
            description: 'Поможем с вводом здания в эксплуатацию, а также предоставим виртуальный клон здания, позволяющий прогнозировать ремонт технологического оборудования и инженерных систем',
            image: image5 },
        { id: 6, title: 'Результат',
            description: 'Запускаем готовый эксплуатируемый объект партнера',
            image: image6 },
    ];

    const [selectedStage, setSelectedStage] = useState(1);

    const handleCardClick = (stageId) => {
        setSelectedStage(stageId);
    };
    return (
        <div className="building-container">
            <div className="building" data-aos="fade-up">
                <div className="images">
                    {stagesData.map((stage) => (
                        <img
                            key={stage.id}
                            src={stage.image}
                            alt={`Этап ${stage.id}`}
                            className={`building-image ${
                                selectedStage === stage.id ? 'active' : ''
                            }`}
                        />
                    ))}
                </div>
                <div className="stages" itemScope="" itemType="http://schema.org/Service">
                    {stagesData.map((stage) => (
                        <div
                            key={stage.id}
                            className={`stage-card ${selectedStage === stage.id ? 'active box-pink' : ''}`}
                            onClick={() => handleCardClick(stage.id)}

                        >
                            <p className="fs-3 mb-0">{stage.id}. <span itemprop="name">{stage.title}</span></p>
                            <p className={`stage-description mt-1 ${
                                selectedStage === stage.id ? 'active' : ''
                            }`}>{stage.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default React.memo(BuildingProcess);
