
import citationCircleWeb from '../assets/mainPage/image-circle.webp';
import mainImgWebp from '../assets/mainPage/image.webp';
import {ReactComponent as YourSvg} from '../assets/building.svg';
import {ReactComponent as DesignServices} from '../assets/design-services.svg';
import {ReactComponent as EmojiPeople} from '../assets/emoji-people.svg';
import {ReactComponent as Diversity} from '../assets/diversity.svg';
import {ReactComponent as Biotech} from '../assets/biotech.svg';
import Navbar from "../componenets/navbar.js";

// import ImageGallery from "../componenets/image-gallery.js";
import mainPageGallery from "../galleries/mainPage.js";
import {Row, Col} from 'react-bootstrap';

import '../styles/button.css';
import React, {lazy, useEffect} from "react";
import { Suspense } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';




function MainPage() {
    useEffect(() => {
        AOS.init();
    }, []);

    const ImageGallery = lazy(() => import("../componenets/image-gallery.js"))

    return (
        <div>
            <Navbar></Navbar>
            <div className="first-element text-container">

                <div className="location">
                    <YourSvg></YourSvg>
                    <p>Москва, Россия</p>
                </div>
                <div className="container-fluid">
                    <Row className="main-header mt-0 align-items-end">
                        <Col className="col-md-7">
                            <p className="title mt-0 mb-0">МЫ - ПРОЕКТНАЯ <br/> ИНЖИНИРИНГОВАЯ <br/> КОМПАНИЯ</p>
                        </Col>

                        <Col className="col-md-5">
                            <p className="subtitle " itemprop="description">МВМ - это команда людей с большим опытом проектирования в России и за
                                рубежом, с реальным опытом ведения комплексных процессов строительства, поставок
                                оборудования,
                                получения всех необходимых разрешений для запуска бизнеса, которая сделала упор на том, что
                                является сердцем любого высокотехнологичного бизнеса - на самой технологии.</p>
                        </Col>
                    </Row>
                </div>

                <div itemScope="" itemType="http://schema.org/ImageObject">
                    <picture >
                        <img
                            itemprop="contentUrl"
                            src={mainImgWebp}
                            alt="Большое красивое здание"
                            id="main-img"
                            style={{
                                width: "100%",
                                height: "80vh",
                                objectFit: "cover",
                                backgroundColor: "gray",
                            }}
                            loading="lazy"
                        />
                    </picture>
                </div>


                <div className="numbers my-3">
                    <div className="number-block" data-aos="fade-up">
                        <h3 className="fs-1 mb-0">100+</h3>
                        <p className="fs-3 mt-0">Создано <br/> проектов</p>
                    </div>
                    <div className="number-block" data-aos="fade-up">
                        <h3 className="fs-1 mb-0">10+</h3>
                        <p className="fs-3 mt-0">Лет опыта</p>
                    </div>
                    <div className="number-block" data-aos="fade-up">
                        <h3 className="fs-1 mb-0">50+</h3>
                        <p className="fs-3 mt-0">Сотрудников<br/>в команде</p>
                    </div>
                    <div className="number-block" data-aos="fade-up">
                        <h3 className="fs-1 mb-0">100+</h3>
                        <p className="fs-3 mt-0">Довольных <br/> клиентов</p>
                    </div>
                </div>
                <div className="corner corner-bottom-left"></div>
                <div className="corner corner-bottom-right"></div>
            </div>


            <div className="box box-pink">
                <div className="text-container">
                    <div className="container-fluid">
                        <Row>
                            <Col xs={12} md={9}>
                                <p className="fs-5 fst-italic fw-light mb-1 pb-0">{"// Наша миссия"}</p>
                                <p className="mt-0 fs-3 lh-sm">Сделать проектирование открытым и понятным для Заказчика
                                    путём
                                    внедрения
                                    Заказчика в организационный процесс, где он видит все стадии реализации проекта в режиме
                                    реального времени.</p>
                            </Col>
                            <Col xs={12} md={3} className="d-flex flex-column align-items-md-end align-items-start">
                                <div className="image-circle" style={{backgroundImage: `url(${citationCircleWeb})`}}></div>
                            </Col>
                        </Row>
                    </div>


                </div>
            </div>


            <div>
                <div className="text-container" style={{paddingBottom: 3 + "%"}}>
                    <div className="corner corner-top-right"></div>
                    <p className="fs-5 fst-italic fw-light mb-0 mt-4 gray-text">{"// Основные услуги"}</p>

                    <div className="center-obj">
                        <div itemScope="" itemType="http://schema.org/Service" className="services fs-2 mt-4">
                            <div className="service-item" data-aos="fade-up">
                                <div className="service-icon">
                                    <DesignServices></DesignServices>
                                </div>
                                <p className="mt-0" itemprop="name">Разработка <br/> документации</p>
                            </div>

                            <div className="service-item" data-aos="fade-up">
                                <div className="service-icon">
                                    <Diversity></Diversity>
                                </div>
                                <p itemprop="name">Технический <br/> надзор</p>
                            </div>

                            <div className="service-item" data-aos="fade-up">
                                <div className="service-icon">
                                    <EmojiPeople></EmojiPeople>
                                </div>
                                <p itemprop="name">Техническое <br/> сопровождение <br/> заказчика</p>
                            </div>

                            <div className="service-item" data-aos="fade-up">
                                <div className="service-icon">
                                    <Biotech></Biotech>
                                </div>
                                <p itemprop="name">Комплексное <br/> обследование <br/> сооружений</p>
                            </div>
                        </div>


                    </div>
                    <div className="d-flex justify-content-center">
                        <a href="/additional-services">
                            <button className="learn-more gray-text" style={{width: 25 + "rem"}}
                                    data-aos="flip-down">
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow"></span>
                        </span>
                                <span className="button-text"> Дополнительные услуги</span>
                            </button>
                        </a>
                    </div>
                    <hr></hr>

                    <h2 className="fs-5 fst-italic fw-light mb-0 gray-text">{"// Наше портфолио"}</h2>


                    <Suspense fallback={<div>Loading...</div>}>
                        <ImageGallery images={mainPageGallery}></ImageGallery>
                    </Suspense>
                    <div className="d-flex justify-content-center">
                        <a href="/objects">
                            <button className="learn-more gray-text" style={{width: 15 + "rem"}} data-aos="flip-down">
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow"></span>
                        </span>
                                <span className="button-text"> Больше работ</span>
                            </button>
                        </a>
                    </div>

                    <div className="corner corner-bottom-left"></div>
                    <div className="corner corner-bottom-right"></div>
                </div>


            </div>

        </div>

    );
}

export default React.memo(MainPage);
