import Navbar from "../componenets/navbar";
import '../styles/about-us.css';
import React, {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';




function PrivacyPage() {
    useEffect(() => {
        AOS.init(); // Инициализируйте AOS
    }, []);

    return (
        <div>
            <Navbar></Navbar>
            <div className="first-element text-container pb-5">
                <p className="fs-1 fw-light py-1 mt-4 gray-text">Политика обработки персональных данных</p>
                <div className="corner corner-top-right"></div>
                <p data-aos="fade-up">
                    Мы получаем, собираем и храним любую информацию, которую вы вводите на нашем сайте или предоставляете нам любым другим способом. Кроме того, мы собираем IP-адрес, используемый для подключения вашего компьютера к интернету;  адрес эл. почты;  информацию о компьютере и подключении.  Мы используем программные инструменты для измерения и сбора информации о сеансе, включая время отклика страницы, продолжительность посещений определенных страниц, информацию о взаимодействии со страницей и методы, используемые для просмотра информации вне страницы. Мы также собираем личную информацию (включая имя, адрес эл. почты);

                    Мы собираем персональные данные и информацию неличного характера для следующих целей:
                </p>
                    <ul>
                        <li data-aos="fade-up">Для предоставления услуг и управления ими;</li>
                        <li data-aos="fade-up">Для предоставления нашим пользователям помощи и технической поддержки;</li>
                        <li data-aos="fade-up">Чтобы иметь возможность отправлять нашим посетителям и пользователям общие или персонализированные уведомления и рекламные сообщения;</li>
                        <li data-aos="fade-up">Для создания агрегированных статистических данных и другой агрегированной и/или вытекающей информации неличного характера, которую мы или наши деловые партнеры можем использовать для предоставления и улучшения наших услуг;</li>
                        <li data-aos="fade-up">Для соблюдения применимых законов и регламентов.</li>
                    </ul>
                <p data-aos="fade-up">
                    Мы можем связаться с вами, чтобы уведомить вас о состоянии вашего аккаунта; устранить неполадки с вашим аккаунтом; разрешить спор; собрать причитающиеся платежи или взносы; узнать ваше мнение с помощью опроса или анкеты; отправить новости о нашей компании; или по другой причине, для соблюдения нашего пользовательского соглашения, применимого национального законодательства и любого соглашения, которое мы можем заключить с вами. Для этих целей мы можем связаться с вами по электронной почте, телефону, текстовым сообщениям или по почте.
                </p>
                <p data-aos="fade-up">
                    Если вы больше не хотите, чтобы мы обрабатывали ваши данные, напишите нам по адресу info@mvm-mos.ru  или отправьте нам письмо по адресу: 109028, г. Москва, Большой Трёхсвятительский пер.2/1, строение No1, помещение No I, комната No 13 (офис No411).
                </p>
                <p data-aos="fade-up">
                    Мы оставляем за собой право изменять эту политику конфиденциальности в любое время, поэтому вам следует регулярно ее просматривать. Изменения и пояснения вступают в силу сразу же после их публикации на сайте. Если мы внесем в эту политику существенные изменения, мы сообщим вам об обновлении политики, чтобы вы знали, какую информацию мы собираем, как мы ее используем и при каких обстоятельствах мы передаем ее третьим лицам.
                </p>
                <p data-aos="fade-up">
                    Если вы хотите получить доступ, исправить, изменить или удалить любую имеющуюся у нас личную информацию, вы можете написать нам по адресу info@mvm-mos.ru  или отправьте нам письмо по адресу: 109028, г. Москва, Большой Трёхсвятительский пер.2/1, строение No1, помещение No I, комната No 13 (офис No411).

                </p>
            </div>


        </div>

    )
}

export default React.memo(PrivacyPage);